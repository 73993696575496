import { useTranslation } from "react-i18next";
import { Container, Card, Image, SimpleGrid, Space } from '@mantine/core';
import { useStyles } from "../components/styles";
import Info_bild from "./informieren.svg";
import Persoenlich_bild from "./persönlich.svg";
import Respektvoll_bild from "./respektvoll.svg";
import Unterstuetzen_bild from "./unterstützen.svg";
import Vertraulich_bild from "./vertraulich.svg";
import Zuhoeren_bild from "./zuhören.svg";
import Leah from "./leah.jpg";
import Jannik from "./jannik.jpg";
import Laura from "./laura.jpg";
import ErikPloeger from "./Erik-Ploeger.jpg";
import PuiSan from "./puisan.jpg";
import ErikSkof from "./Erik-Skof.jpg";
import MaximilianNaegele from "./Maximilian-Naegele.jpg";
import RobertCoveri from "./Robert-Coveri.jpg";
import NicholasHohaus from "./Nicholas-Hohaus.jpg";
/**
 * This is the helpdesk page of vseth help
 */
export const Helpdesk = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <Container>
        <h1>{t("Helpdesk.title")}</h1>
        <p>{t("Helpdesk.beschr2")}</p>
        <p>{t("Helpdesk.beschr3")}</p>
        <p>{t("Helpdesk.beschr4")}</p>
        <p>{t("Helpdesk.beschr5")}</p>
        <h2>{t("Helpdesk.werwirsind_title")}</h2>
        <p>{t("Helpdesk.werwirsind_txt")}</p>
        <h2>{t("Helpdesk.grundsaetze_title")}</h2>
        <SimpleGrid spacing="xl" cols={3} breakpoints={[{maxWidth: 'md', cols: 2}, {maxWidth: 'sm', cols: 1}]}>
            <Card shadow="md">
                <Card.Section withBorder>
                    <Image src={Respektvoll_bild}/>
                </Card.Section> 
                <Card.Section className={classes.cardText}>
                    <h4>{t("Helpdesk.respektvoll_title")}</h4>
                    {t("Helpdesk.respektvoll_txt")}
                </Card.Section>
            </Card>
            <Card shadow="md">
                <Card.Section withBorder>
                    <Image src={Vertraulich_bild}/>
                </Card.Section>
                <Card.Section className={classes.cardText}>
                    <h4>{t("Helpdesk.vertraulich_title")}</h4>
                    {t("Helpdesk.vertraulich_txt")}
                </Card.Section>
            </Card>
            <Card shadow="md">
                <Card.Section withBorder>
                    <Image src={Persoenlich_bild}/>
                </Card.Section>
                <Card.Section className={classes.cardText}>
                    <h4>{t("Helpdesk.persönlich_title")}</h4>
                    {t("Helpdesk.persönlich_txt")}
                </Card.Section>
            </Card>
        </SimpleGrid>
        <h2 className="mb-3 mt-3">{t("Helpdesk.vorgehen_title")}</h2>
        <SimpleGrid spacing="xl" cols={3} breakpoints={[{maxWidth: 'md', cols: 2}, {maxWidth: 'sm', cols: 1}]}>
            <Card shadow="md">
                <Card.Section withBorder>
                    <Image src={Zuhoeren_bild}/>
                </Card.Section>
                <Card.Section className={classes.cardText}>
                    <h4>{t("Helpdesk.zuhören_title")}</h4>
                    {t("Helpdesk.zuhören_txt")}
                </Card.Section>
            </Card>
            <Card shadow="md">
                <Card.Section withBorder>
                    <Image src={Unterstuetzen_bild}/>
                </Card.Section>
                <Card.Section className={classes.cardText}>
                    <h4>{t("Helpdesk.unterstützen_title")}</h4>
                    {t("Helpdesk.unterstützen_txt")}
                </Card.Section>
            </Card>
            <Card shadow="md">
                <Card.Section withBorder>
                    <Image src={Info_bild}/>
                </Card.Section>
                <Card.Section className={classes.cardText}>
                    <h4>{t("Helpdesk.informieren_title")}</h4>
                    {t("Helpdesk.informieren_txt")}
                </Card.Section>
            </Card>
        </SimpleGrid>
        <Space h="sm" />
        <Card className={classes.inverseCard}>
            <h2>{t("Helpdesk.contacttitle")}</h2>
            <p>{t("Helpdesk.contact_txt")}</p>
        </Card>
        <Space h="sm" />
        <SimpleGrid spacing="xl" cols={4} breakpoints={[{maxWidth: 'md', cols: 3}, {maxWidth: 'sm', cols: 2}, {maxWidth: 'xs', cols: 1}]}>
            <Card shadow="md">
                <Card.Section withBorder>
                    <Image src={Leah} />
                </Card.Section>
                <Card.Section withBorder className={classes.cardText}>
                    {t("Leah Mönkemöller, ")}
                    {t("mleah[at]student.ethz.ch ")}
                </Card.Section>
                <Card.Section withBorder className={classes.cardText}>
                    {t("DE, EN ")}
                </Card.Section>
                <Card.Section withBorder className={classes.cardText}>
                    {t("4. M.Sc. Interdisciplinary Sciences	")}
                </Card.Section>
                <Card.Section withBorder className={classes.cardText}>
                    {t("Frankfurt am Main (DE) ")}
                </Card.Section>
            </Card>
            <Card shadow="md">
                <Card.Section withBorder>
                    <Image src={ErikSkof}/>
                </Card.Section>
                <Card.Section withBorder className={classes.cardText}>
                    {t("Erik Skof, ")}
                    {t("erskof[at]student.ethz.ch ")}
                </Card.Section>
                <Card.Section withBorder className={classes.cardText}>
                    {t("DE, CS, EN ")}
                </Card.Section>
                <Card.Section withBorder className={classes.cardText}>
                    {t("3. BSc. Chemical Engineering")}
                </Card.Section>
                <Card.Section withBorder className={classes.cardText}>
                    {t("Wien (AT) ")}
                </Card.Section>
            </Card>
            <Card shadow="md">
                <Card.Section withBorder>
                    <Image src={Jannik}/>
                </Card.Section>
                <Card.Section withBorder className={classes.cardText}>
                    {t("Jannik Kochert, ")}
                    {t("kjannik[at]student.ethz.ch ")}
                </Card.Section>
                <Card.Section withBorder className={classes.cardText}>
                    {t("DE, CH, EN ")}
                </Card.Section>
                <Card.Section withBorder className={classes.cardText}>
                    {t("3. MSc. Mathematics ")}
                </Card.Section>
                <Card.Section withBorder className={classes.cardText}>
                    {t("Zofingen (CH) ")}
                </Card.Section>
            </Card>
            <Card shadow="md">
                <Card.Section withBorder>
                    <Image src={ErikPloeger}/>
                </Card.Section>
                <Card.Section withBorder className={classes.cardText}>
                    {t("Erik Plöger, ")}
                    {t("eploeger[at]student.ethz.ch ")}
                    </Card.Section>
                <Card.Section withBorder className={classes.cardText}>
                    {t("DE, EN ")}
                    </Card.Section>
                <Card.Section withBorder className={classes.cardText}>
                    {t("3. B.Sc. Chemical Engineering ")}
                    </Card.Section>
                <Card.Section withBorder className={classes.cardText}>
                    {t("Hamburg (DE) ")}
                </Card.Section>
            </Card>
            <Card shadow="md">
                <Card.Section withBorder>
                    <Image src={Laura}/>
                </Card.Section>
                <Card.Section withBorder className={classes.cardText}>
                    {t("Laura Wilhelm, ")}
                    {t("lawilhelm[at]student.ethz.ch ")}
                    </Card.Section>
                <Card.Section withBorder className={classes.cardText}>
                    {t("DE, EN ")}
                    </Card.Section>
                <Card.Section withBorder className={classes.cardText}>
                    {t("6. B.Sc. Chemical Biology - Biochemistry ")}
                    </Card.Section>
                <Card.Section withBorder className={classes.cardText}>
                    {t("Chicago (US) / Köln (DE)")}
                </Card.Section>
            </Card>
            <Card shadow="md">
                <Card.Section withBorder>
                    <Image src={MaximilianNaegele}/>
                </Card.Section>
                <Card.Section withBorder className={classes.cardText}>
                    {t("Maximilian Nägele, ")}
                    {t("mnaege[at]student.ethz.ch ")}
                    </Card.Section>
                <Card.Section withBorder className={classes.cardText}>
                    {t("DE, EN ")}
                    </Card.Section>
                <Card.Section withBorder className={classes.cardText}>
                    {t("3. BSc. Chemical Biology - Biochemistry ")}
                    </Card.Section>
                <Card.Section withBorder className={classes.cardText}>
                    {t("Stuttgart (DE)")}
                </Card.Section>
            </Card>
            <Card shadow="md">
                <Card.Section withBorder>
                    <Image src={PuiSan}/>
                </Card.Section>
                <Card.Section withBorder className={classes.cardText}>
                    {t("PuiSan Nghiem, ")}
                    {t("nghiemp[at]student.ethz.ch ")}
                    </Card.Section>
                <Card.Section withBorder className={classes.cardText}>
                    {t("Mandarin, Cantonese, EN, DE, CH, ES ")}
                    </Card.Section>
                <Card.Section withBorder className={classes.cardText}>
                    {t("8. B.Sc. Pharmaceutical Science ")}
                    </Card.Section>
                <Card.Section withBorder className={classes.cardText}>
                    {t("Solothurn (CH)")}
                </Card.Section>
            </Card>
            <Card shadow="md">
                <Card.Section withBorder>
                    <Image src={RobertCoveri}/>
                </Card.Section>
                <Card.Section withBorder className={classes.cardText}>
                    {t("Robert Coveri, ")}
                    {t("rcoveri[at]student.ethz.ch ")}
                    </Card.Section>
                <Card.Section withBorder className={classes.cardText}>
                    {t("DE, CH, EN ")}
                    </Card.Section>
                <Card.Section withBorder className={classes.cardText}>
                    {t("3. B.Sc. Chemical Engineering ")}
                    </Card.Section>
                <Card.Section withBorder className={classes.cardText}>
                    {t("Zug (CH)")}
                </Card.Section>
            </Card>
            <Card shadow="md">
                <Card.Section withBorder>
                    <Image src={NicholasHohaus}/>
                </Card.Section>
                <Card.Section withBorder className={classes.cardText}>
                    {t("Nicholas Hohaus, ")}
                    {t("nhohaus[at]student.ethz.ch ")}
                    </Card.Section>
                <Card.Section withBorder className={classes.cardText}>
                    {t("DE, FR, EN ")}
                    </Card.Section>
                <Card.Section withBorder className={classes.cardText}>
                    {t("3. B.Sc. Chemical Engineering ")}
                    </Card.Section>
                <Card.Section withBorder className={classes.cardText}>
                    {t("Genf (CH)")}
                </Card.Section>
            </Card>
        </SimpleGrid>
    </Container>

    );
};

